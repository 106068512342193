
import { onMounted, computed, reactive, toRefs, h, watch, ref, defineComponent } from "vue";
import { useStore } from "@/store/index";

export default defineComponent({
    setup(props, context) {
        const store = useStore();

        const state = reactive({
            colorBy: computed(() => store.state.colorBy),
            renderState: computed(() => store.state.renderState),
            modelType: computed(() => store.state.modelType),
            colorMsg: ""
        });

        const lowLabel = () => {
            switch (state.colorBy) {
                case 'pos_mod_5':
                case 'row':
                case 'column':
                    return "0"
                case 'position':
                    return "start"
                case 'token_length':
                case 'sent_length':
                    return "short"
                case 'embed_norm':
                case 'token_freq':
                    return "low"
                case 'punctuation':
                    return ".?!"
                default:
                    ""
            }
        }
        const highLabel = () => {
            switch (state.colorBy) {
                case 'position':
                    return "end"
                case 'row':
                case 'column':
                    return state.modelType == "vit-16" ? 13 : 6
                case 'pos_mod_5':
                    return "4"
                case 'embed_norm':
                case 'token_freq':
                    return "high"
                case 'token_length':
                case 'sent_length':
                    return "long"
                case 'punctuation':
                    return "abc"
                default:
                    ""
            }
        }

        // change msg below legend
        const setColorMsg = (msg: string) => {
            state.colorMsg = msg;
        }

        context.expose({
            setColorMsg
        });

        return {
            ...toRefs(state),
            highLabel,
            lowLabel
        };
    }
})

