
import "bootstrap/dist/css/bootstrap.css";

import { defineComponent } from "vue";
import { useStore } from "@/store/index";

import Projection from "./Projection/Projection.vue";
import AttnMapWrapper from "./AttnMap/AttnMapWrapper.vue";

import { onMounted, computed, reactive, toRefs, h, watch, ref } from "vue";

export default defineComponent({
  name: "App",
  components: { Projection, AttnMapWrapper },
  setup() {
    const store = useStore();

    const projection = ref(null);

    const state = reactive({
      storeLayer: computed(() => store.state.layer),
      storeHead: computed(() => store.state.head),
      layernum: "" as string | number,
      headnum: "" as string | number,
      mode: computed(() => store.state.mode),
      view: computed(() => store.state.view),
      userTheme: computed(() => store.state.userTheme),
      icon: "moon",
      modalVisible: true,
      showAttn: computed(() => store.state.showAttn)
    });

    // Init the store to read data from backend
    onMounted(async () => {
      await store.dispatch("init");
      // setTheme(state.userTheme);
    });

    // update graph settings based on dropdown option selected
    const handleChange = function (type: string, value: any) {
      if (type == "layer") {
        state.layernum = value;
      } else {
        state.headnum = value;
      }
    };

    // zoom to plot based on layer and head selected
    const zoomToPlot = () => {
      let layer = state.layernum;
      let head = state.headnum;
      (projection.value as any).zoomToPlot(layer, head);
    };

    const onClickReset = () => {
      (projection.value as any).onClickReset();
    };

    const resetToMatrix = () => {
      (projection.value as any).resetToMatrix();
    };

    // switch between light and dark mode
    const toggleTheme = () => {
      console.log("toggle theme");
      const activeTheme = state.userTheme;
      if (activeTheme === "light-theme") {
        setTheme("dark-theme");
      } else {
        setTheme("light-theme");
      }
    }

    const getTheme = () => {
      console.log("get theme");
      return localStorage.getItem("user-theme") || "light-theme";
    }

    const setTheme = (theme: string) => {
      console.log("set theme");
      // localStorage.setItem("user-theme", theme);
      document.documentElement.className = theme;
      store.commit('setUserTheme', theme);

      if (theme == 'dark-theme') {
        state.icon = "sun";
      } else {
        state.icon = "moon";
      }
    };

    const getMediaPreference = () => {
      console.log("getMediaPreference");
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    };

    // show modal
    const showModal = () => {
      state.modalVisible = true;
    }

    const closeModal = () => {
      state.modalVisible = false;
    }


    watch([() => state.storeHead, () => state.storeLayer],
      () => {
        state.layernum = state.storeLayer;
        state.headnum = state.storeHead;
      }

    );
    return {
      ...toRefs(state),
      projection,
      handleChange,
      zoomToPlot,
      onClickReset,
      resetToMatrix,
      toggleTheme,
      getTheme,
      setTheme,
      getMediaPreference,
      closeModal,
      showModal
    };
  },
});
