
import { onMounted, computed, reactive, toRefs, h, watch } from "vue";
import * as _ from "underscore";
import { useStore } from "@/store/index";
import AttnMap from "./AttnMap.vue";
import AggAttnMap from "./AggAttnMap.vue";
import ImageAttnMap from "./ImageAttnMap.vue";

export default {
    components: { AttnMap, AggAttnMap, ImageAttnMap },
    setup() {
        const store = useStore();

        const state = reactive({
            modelType: computed(() => store.state.modelType),
            view: computed(() => store.state.view),
            hideFirst: false,
            hideLast: false,
            weightByNorm: false,
            showAgg: computed(() => store.state.showAgg)
        });

        // show aggregate attention
        const showAggAttention = () => {
            store.commit("setShowAgg", true);
        }

        return {
            ...toRefs(state),
            showAggAttention
        };
    },
};
