
import { onMounted, computed, reactive, toRefs, h, watch } from "vue";
import * as _ from "underscore";
import { useStore } from "@/store/index";
import { BitmapLayer } from "@deck.gl/layers/typed";
import { Deck } from "@deck.gl/core/typed";

export default {
    components: {},
    setup() {
        const store = useStore();

        const state = reactive({
            attentionByToken: computed(() => store.state.attentionByToken),
            showAttn: computed(() => store.state.showAttn),
            attnMsg: "attention within selected image",
            highlightedTokenIndices: computed(() => store.state.highlightedTokenIndices),
            view: computed(() => store.state.view),
            mode: computed(() => store.state.mode),
            attentionLoading: computed(() => store.state.attentionLoading),
            userTheme: computed(() => store.state.userTheme),
            vizType: "top_arrow",
            vizOptions: [
                {
                    value: "cur_token",
                    label: "attention to selected token"
                },
                {
                    value: "top_arrow",
                    label: "highest attention to each token"
                },
                {
                    value: "all_top",
                    label: "all high attention flows"
                }
            ]
        });

        let deckgl2 = {} as Deck;

        // start bertviz for images
        const bertviz = () => {
            document.querySelectorAll("canvas#bertviz + .deck-tooltip").forEach((v) => v.remove());
            // parse info from data
            let { attentionByToken } = state;

            const toOriginalImageLayer = new BitmapLayer({
                id: 'bertviz-image',
                bounds: [-70, 2.5, 70, 80.5],
                image: attentionByToken.token.originalImagePath,
                pickable: false,
            });

            const toOverlaidlImageLayer = new BitmapLayer({
                id: 'bertviz-overlay',
                bounds: [-70, -84.5, 70, -2.5],
                image: attentionByToken.token.originalPatchPath,
                pickable: false,
            });

            const toArrowedlImageLayer = new BitmapLayer({
                id: 'bertviz-arrowed',
                bounds: [-70, -80.5, 70, -2.5],
                image: attentionByToken.token.sentence,
                pickable: false,
            });

            const toPatchedImageLayer = new BitmapLayer({
                id: 'patched-image',
                bounds: [-70, 2.5, 70, 80.5],
                image: attentionByToken.token.value,
                pickable: false,
            });

            const toArrowedLayer = new BitmapLayer({
                id: 'bertviz-pure',
                bounds: [-70, -80.5, 70, -2.5],
                image: attentionByToken.token.length,
                pickable: false,
            });

            if (state.vizType == "all_top") { // attn flow only (but all top attn lines shown)
                deckgl2 = new Deck({
                    canvas: "bertviz",
                    layers: [toPatchedImageLayer, toArrowedLayer]
                });
            }
            else if (state.vizType == "top_arrow") { // top attn arrow for whole image
                deckgl2 = new Deck({
                    canvas: "bertviz",
                    layers: [toOriginalImageLayer, toArrowedlImageLayer]
                });
            } else { // heatmap for current patch
                deckgl2 = new Deck({
                    canvas: "bertviz",
                    layers: [toOriginalImageLayer, toOverlaidlImageLayer]
                });
            }

            if (state.attentionLoading) {
                // set to false if still loading
                store.commit('updateAttentionLoading', false);

            }

        }
        const clearAttn = () => {
            if (state.view != "search") {
                store.commit("setHighlightedTokenIndices", []);
            }
        }

        watch(
            () => [state.attentionByToken, state.vizType],
            () => {
                // draw attention plot
                if (!state.showAttn) {
                    store.commit("setShowAttn", true);
                }
                bertviz();
            }
        );

        watch(
            () => [state.view, state.mode],
            () => {
                if (state.view != 'attn') {
                    store.commit("setShowAttn", false);
                }
            }
        )

        watch(
            () => [state.showAttn],
            () => {
                if (!state.showAttn) {
                    clearAttn();
                }
            }
        )

        return {
            ...toRefs(state),
            clearAttn,
            bertviz
        };
    },
};
